import React, { useState } from 'react'
import axios from "axios";
import { useSignIn } from 'react-auth-kit'
import { useAuthUser, useAuthHeader} from 'react-auth-kit'
import { useNavigate } from 'react-router-dom';
import './Login.css';
const backend = process.env.REACT_APP_BACKEND_API;

export default function Password() {
    // Obtendo informações do usuário autenticado
    const auth = useAuthUser()
    const authHeader = useAuthHeader();
    const token = authHeader();

    const [password, setPassword] = useState("");
    const [newpassword, setNewPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    
    const navigate = useNavigate();
    const signIn = useSignIn();
    
    const handleSubmit = (e) => {
        e.preventDefault();
        if (newpassword !== repeatPassword) {
            setErrorMessage("As novas senhas não coincidem.");
            setLoading(false);
            return;
        }
        const configuration = {
            method: "put", // Mudando para o método PUT
            url: backend + "/users/change-password", // Atualizando a URL para a mudança de senha
            data: {
                email: auth().email,
                oldPassword: password,
                newPassword: newpassword,
            },
            headers: {
                Authorization: `${token}`,
            }
        };
        setLoading(true);
        axios(configuration)
        .then((res) => {
            setLoading(false);
            setErrorMessage(res.data.message || "Senha alterada com sucesso.");
            navigate("/"); // Redireciona após a mudança de senha
        })
        .catch((error) => {
            setLoading(false);
            setErrorMessage(error.response?.data?.message || "Erro ao mudar a senha. Tente novamente."); 
        });
    }
    return (
        <div className="fullpage">
            <main className="form-signin">
                <form onSubmit={(e) => handleSubmit(e)}>
                    <img alt="Logo" src="logo512.png" className="logo" />
                    <h1 className="mb-2">HealthPad</h1>
                    <p>IA para medicina</p>
                    <p>Por favor, altere sua senha para continuar...</p>
                    {errorMessage && (
                        <p className="alert alert-danger" role="alert">{errorMessage}</p>
                    )}
                    {loading && (
                        <p className="alert alert-warning" role="alert"> Carregando... </p>
                    )}
                    <div className="form-floating">
                        <input 
                            type={"password"} 
                            disabled={loading}
                            className="form-control" 
                            id="floatingOldPassword"
                            placeholder="Senha antiga"
                            onChange={(e) => {
                                setPassword(e.target.value);
                                setErrorMessage();
                            }}
                        />
                        <label htmlFor="floatingOldPassword">Senha Antiga</label>
                    </div>
                    <div className="form-floating">
                        <input 
                            type={"password"} 
                            disabled={loading}
                            className="form-control" 
                            id="floatingNewPassword"
                            placeholder="Nova Senha"
                            onChange={(e) => {
                                setNewPassword(e.target.value);
                                setErrorMessage();
                            }}
                        />
                        <label htmlFor="floatingNewPassword">Nova Senha</label>
                    </div>
                    <div className="form-floating">
                        <input 
                            type={"password"} 
                            disabled={loading}
                            className="form-control" 
                            id="floatingRepeatPassword"
                            placeholder="Repetir Nova Senha"
                            onChange={(e) => {
                                setRepeatPassword(e.target.value);
                                setErrorMessage();
                            }}
                        />
                        <label htmlFor="floatingRepeatPassword">Repetir Nova Senha</label>
                    </div>
                    <button disabled={loading} className="w-100 btn btn-lg btn-success" type="submit">Alterar Senha</button>
                    <p className="mt-5 mb-3">&copy; 2024 <a className="text-decoration-none" href="https://www.tbmed.com.br">TBMed Serviços Médicos LTDA.</a></p>
                </form>
            </main>
        </div>
    )
}