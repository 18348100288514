import axios from "axios";
import React, { useState, useRef, useEffect  } from "react";
import {useNavigate } from 'react-router-dom';
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import './Editor.css';
import { useAuthHeader} from 'react-auth-kit'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear} from '@fortawesome/free-solid-svg-icons'

const backend = process.env.REACT_APP_BACKEND_API;

function Principal() {
  const [texto, setTexto] = useState("");
  const [empty, setEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const quillRef = useRef(null);
  const [modelList, setModelList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const authHeader = useAuthHeader();
  const token = authHeader();
  const navigate = useNavigate();
  const versao = process.env.REACT_APP_VERSAO; // Variável para versão
  
  useEffect(() => {
    async function fetchData(){
      try {
        const config = {
          headers: {
            Authorization: `${token}`,
          },
        };
        const response = await axios.get(backend + "/models", config);
        setModelList(response.data);
        const response2 = await axios.get(backend + "/templates", config);
        setTemplateList(response2.data);
      } catch (error) {
          console.log(error);
      }
    }
    fetchData();
  }, [token]);
  

  async function processaTexto(regra) {
    setIsLoading(true);
    try {
      /*const response = await axios.post(
        //"https://medmind.openai.azure.com/openai/deployments/gpt-35/chat/completions?api-version=2023-03-15-preview",
        "https://api.openai.com/v1/chat/completions",
        {
          "model": "gpt-4-1106-preview",
          "messages": [
              {
              "role": "user",
              "content": "Utilizando as informações do paciente fornecidas, atue como um auxiliar na organização de prontuários médicos. Utilize apenas as informações que forem dadas, não crie dados novos. " + regra + " Informações do paciente: ```" + texto + "```"
              }
          ],          
          "max_tokens": 4000,
          "n": 1,
        },
        {
          "headers": {
            "Authorization" : "Bearer " + openai_key,
            //"api-key": azure_key,
            "Content-Type": "application/json",
          },
        }
      );
      
      setTexto(response.data.choices[0].message.content.replace(/\n/g, '<br>'));
      handleTextoChange(response.data.choices[0].message.content.replace(/\n/g, '<br>'));*/

      const response = await axios.post(
        backend + "/gen", 
      {
       "prompt": "Utilizando as informações do paciente fornecidas, atue como um auxiliar na organização de prontuários médicos. Utilize apenas as informações que forem dadas, não crie dados novos. " + regra + " Informações do paciente: ```" + texto + "```",
      },
      {
        "headers": {
        "Authorization": `${token}`,
        },
      }
      );

      setTexto(response.data.result.replace(/\n/g, '<br>'));
      handleTextoChange(response.data.result.replace(/\n/g, '<br>'));

    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };
    
  const handleCopyToClipboard = () => {
    const clipboardItem = new ClipboardItem({
      "text/html": new Blob(
          [texto],
          { type: "text/html" }
      ),
      "text/plain": new Blob(
          [texto.replace(/<\/p>/g, '\n').replace(/<[^>]*>/g, '')],
          { type: "text/plain" }
      )
    });
    navigator.clipboard.write([clipboardItem]);   
  };
  
  useEffect(() => {
    const regex = /<\s*[^>]*>/g;
    const onlyTags = texto.replace(regex, "").trim() === "";
    setEmpty(texto === "" || onlyTags);
  }, [texto]);

  // Função UNDO
  const [history, setHistory] = useState([""]);

  const handleUndo = () => {
    if (history.length === 0) return;

    const newHistory = [...history];
    const lastState = newHistory.pop();

    setTexto(lastState);
    setHistory(newHistory);
  };

  const handleTextoChange = (value) => {
    setHistory([...history, texto]);
    setTexto(value);
  };
  
  return (
    <Container fluid className="mt-2">
      <Row>
        <Col md={2}>
            <ListGroup className='mb-2'>
              <ListGroup.Item variant="success">
                Formatar com IA
                <FontAwesomeIcon className="mx-1 cursor" onClick={() => navigate("/edit/models")} icon={faGear}/>
              </ListGroup.Item>
              {modelList.map((model) => (
                <ListGroup.Item action disabled={empty} onClick={() => processaTexto(model.data)}>{model.name}</ListGroup.Item>
              ))}
            </ListGroup>
            <ListGroup className='mb-2'>
              <ListGroup.Item variant="success">
                Templates
                <FontAwesomeIcon className="mx-1 cursor" onClick={() => navigate("/edit/templates")} icon={faGear}/>
              </ListGroup.Item>
              {templateList.map((model) => (
                <ListGroup.Item action onClick={() => {setTexto(model.data); handleTextoChange(model.data);}}>{model.name}</ListGroup.Item>
              ))}
            </ListGroup>
            <ListGroup className='mb-2'>
                <ListGroup.Item variant="success">Edição</ListGroup.Item>
                <ListGroup.Item action onClick={handleCopyToClipboard}>Copiar</ListGroup.Item>
                <ListGroup.Item action onClick={handleUndo} disabled={history.length <= 1}>Desfazer</ListGroup.Item>
                <ListGroup.Item action onClick={() => {setTexto("")}}>Excluir</ListGroup.Item>
                <ListGroup.Item action onClick={() => {alert(texto)}}>⚠ Mostrar Texto</ListGroup.Item>
                <ListGroup.Item action onClick={() => {alert(history)}}>⚠ Mostrar histórico</ListGroup.Item>
                
            </ListGroup>

        </Col>
        <Col md={10}>
            <ReactQuill
              ref={quillRef}
              onChange={handleTextoChange}
              value={texto}
              disabled={isLoading}
              modules={{
                toolbar: [
                  ['bold', 'italic', 'underline'],
                ],
              }}
              formats={[
                'bold', 'italic', 'underline',
              ]}
            />

            {isLoading && (
            <div className="loading">
              <img src="./loading.svg" alt="loading" />
            </div>
            )}
          <footer>
            <p className="text-center"><small>⚠ Aviso: textos gerados por inteligência artificial podem conter informações imprecisas ou incorretas. É sua responsabilidade checar as informações antes de usá-las em um contexto real.</small></p>
            <p className="text-center"><small>Versão {versao} - &copy;2024 <a href="http://www.tbmed.com.br">TBMed Serviços Médicos</a> LTDA.</small></p>
          </footer>
        </Col>
      </Row>
    </Container>
  );
}

export default Principal;

/*
            <ListGroup className='mb-2'>
                <ListGroup.Item variant="success">🧑‍🏫 Demonstração</ListGroup.Item>
                {exemplos.map((exemplo) => (
                  <ListGroup.Item action onClick={() => setTexto(exemplo.data)}>{exemplo.nome}</ListGroup.Item>
                ))}
            </ListGroup>

            */