import Login from "./auth/Login";
import Password from "./auth/Password";
import Admin from "./routes/admin";
import NavBar from "./components/navBar";
import Editor from "./components/Editor";
import DBEditor from "./routes/edit";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { RequireAuth, AuthProvider } from "react-auth-kit";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() { 
  return (
    <AuthProvider authType = {'cookie'}
      authName={'_auth'}
      cookieDomain={window.location.hostname}
      cookieSecure={window.location.protocol === "https:"}>
        <BrowserRouter>
            <Routes>
                  <Route path="/login" element={<Login/>} />
                  <Route path={'/'} element={
                    <RequireAuth loginPath={'/login'}>
                        <NavBar/>
                        <Editor/>
                    </RequireAuth>
                  }/>
                  <Route path={'/admin'} element={
                    <RequireAuth loginPath={'/login'}>
                      <NavBar/>
                      <Admin/>
                    </RequireAuth>
                  }/>
                  <Route path={'/edit/models'} element={
                    <RequireAuth loginPath={'/login'}>
                      <NavBar/>
                      <DBEditor type="models"/>
                    </RequireAuth>
                  }/>
                  <Route path={'/edit/templates'} element={
                    <RequireAuth loginPath={'/login'}>
                      <NavBar/>
                      <DBEditor type="templates"/>
                    </RequireAuth>
                  }/>
                  <Route path={'/change-password'} element={
                    <RequireAuth loginPath={'/login'}>
                      <NavBar/>
                      <Password/>
                    </RequireAuth>
                  }/>
            </Routes>
        </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
