/***********
NavBar
Barra de navegacao
**************/

//Imports
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { NavLink, useNavigate } from 'react-router-dom';
import { useSignOut, useAuthUser } from 'react-auth-kit'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket, faScrewdriverWrench} from '@fortawesome/free-solid-svg-icons'
import './navBar.css';

// O componente
export default function NavBar() {
    const signOut = useSignOut();
    const navigate = useNavigate();
    const auth = useAuthUser();
    const logout = () => {
        signOut();
        navigate("/login");
    }
    return (
        <nav className="navbar p-0">
            <div className="row w-100">
                <div className="col-3">
                    <NavLink className="navbar-brand ms-1" to="/">
                        <img alt="logo" className="navlogo" src="./logo192.png" />
                        <span className="navsub">HealthPad</span>
                    </NavLink>
                </div>
                <div className="col-3">
    
                </div>
                <div className="col-6 text-end p-0">
                    <span className="user-name">{auth().name}</span>

                    {auth().role === "admin" && (
                    <OverlayTrigger
                        placement="bottom" 
                        overlay={<Tooltip id='ttadmin'>
                            <span className='color-white'>Admin Panel</span></Tooltip>}>
                        <FontAwesomeIcon className="mx-1 mx-lg-2 cursor logout" onClick={() => navigate("/admin")} icon={faScrewdriverWrench} inverse/>
                    </OverlayTrigger>
                    )}
                    <OverlayTrigger
                        placement="bottom" 
                        overlay={<Tooltip className='color-white' id='ttlogout'>
                                 <span className='color-white'>Sair</span></Tooltip>}>
                        <FontAwesomeIcon className="mx-1 mx-lg-2 cursor logout" onClick={() => logout()} icon={faRightFromBracket} inverse/>
                    </OverlayTrigger>
                </div>
            </div>
        </nav>
        
         
    );
}