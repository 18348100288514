// Importando as bibliotecas necessárias
import React, {useState, useEffect } from "react";
import axios from "axios";
import { useSignOut, useAuthUser, useAuthHeader} from 'react-auth-kit'
import { useNavigate } from 'react-router-dom';

// Definindo a URL do backend a partir das variáveis de ambiente
const backend = process.env.REACT_APP_BACKEND_API;

// Componente principal do painel de administração
export default function Admin() {
    // Obtendo informações do usuário autenticado
    const auth = useAuthUser()
    const authHeader = useAuthHeader();
    const token = authHeader();
    
    // Estado para armazenar a lista de usuários
    const [userList, setUserList] = useState([]);

    // Efeito colateral para buscar dados ao montar o componente
    useEffect(() => {
      fetchData();
    }, []);

    // Função para buscar a lista de usuários do backend
    const fetchData = async () => {
        try {
            const config = {
              headers: {
                Authorization: `${token}`, // Adicionando o token de autenticação no cabeçalho
              },
            };
            // Fazendo a requisição GET para obter a lista de usuários
            const response = await axios.get(backend + "/users", config);
            setUserList(response.data); // Atualizando o estado com a lista de usuário
        } catch (error) {
            console.log(error); // Logando erros no console
        }
    };
    const [newUserName, setNewUserName] = useState("");
    const [newUserEmail, setNewUserEmail] = useState("");
    const [newUserRole, setNewUserRole] = useState("user");

    const user_create = async () => {
        const newUser = {
            name: newUserName,
            email: newUserEmail,
            role: newUserRole
        };

        try {
            const config = {
                headers: {
                    Authorization: `${token}`,
                },
            };
            const response = await axios.post(backend + "/users", {
                name: newUser.name,
                email: newUser.email,
                password: "123456", // Defina uma senha padrão ou gere uma
                role: newUser.role,
                active: "false" 
            }, config);
            fetchData(); // Atualiza a lista de usuários após a criação
            // Limpa os campos após a criação
            setNewUserName("");
            setNewUserEmail("");
            setNewUserRole("user");
        } catch (error) {
            console.log(error);
            alert("Ocorreu um erro ao criar o usuário. Tente novamente."); // Mensagem de erro na tela
        }
    };

    const toggleUserActive = async (userId) => {
      
        try {
            const config = {
                headers: {
                    Authorization: `${token}`,
                },
            };
            const user = userList.find(u => u._id === userId);
            const updatedUser = {
                ...user,
                active: !user.active // Alterna o estado ativo do usuário
            };
            console.log(updatedUser);

            await axios.put(backend + `/users/${userId}`, updatedUser, config);
            fetchData(); // Atualiza a lista de usuários após a alteração
        } catch (error) {
            console.log(error);
        }
    };
    const deleteUser = async (userId) => {
        try {
            const config = {
                headers: {
                    Authorization: `${token}`,
                },
            };
            await axios.delete(backend + `/users/${userId}`, config);
            fetchData(); // Atualiza a lista de usuários após a exclusão
        } catch (error) {
            console.log(error);
        }
    };
    // Verificando se o usuário tem a função de admin
    if (auth().role === "admin"){
        return (
            <div className="container">
              <h1 className="mt-4">Admin Panel</h1>
              <div className="mt-4">
                <h2>Lista de Usuários</h2>
                <ul className="list-group">
                  <table className="table">
                    <thead>
                      <tr>
                        <th><strong>Nome</strong></th>
                        <th><strong>ID</strong></th>
                        <th><strong>Email</strong></th>
                        <th><strong>Função</strong></th>
                        <th><strong>Estado</strong></th>
                        <th><strong>Ações</strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      {userList.map((user) => (
                        <tr key={user._id}>
                          <td><strong>{user.name}</strong></td>
                          <td>{user._id}</td>
                          <td>{user.email}</td>
                          <td>{user.role}</td>
                          <td>
                            <a href="#" onClick={() => toggleUserActive(user._id)}>
                              {user.active ? 'Ativo' : 'Inativo'}
                            </a>
                          </td>
                          <td>
                            {user._id !== auth().id && (
                              <a href="#" onClick={() => deleteUser(user._id)}>Deletar</a>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </ul>
              </div>
              <div className="mt-4">
              <h2>Criar Usuário</h2>
                  <form onSubmit={(e) => { e.preventDefault(); user_create(); }} className="d-flex align-items-center">
                    <input type="text" className="form-control me-2" id="name" placeholder="Nome" value={newUserName} onChange={(e) => setNewUserName(e.target.value)} required />
                    <input type="email" className="form-control me-2" id="email" placeholder="Email" value={newUserEmail} onChange={(e) => setNewUserEmail(e.target.value)} required />
                    <select className="form-select me-2" id="role" value={newUserRole} onChange={(e) => setNewUserRole(e.target.value)} required>
                      <option value="user">User</option>
                      <option value="admin">Admin</option>
                    </select>
                    <button type="submit" className="btn btn-primary">Criar</button>
                  </form>
              </div>
            </div>
          );
    } else {
        // Mensagem de acesso negado para usuários não administradores
        return (
        <p>User not allowed</p>
        );
    }
}


//<li className="list-group-item" key={user._id}>
//<strong>{user.name}</strong> - {user._id} - {user.email} - {user.role} - <a href="#" onClick={() => toggleUserActive(user._id)}>{user.active ? 'Ativo' : 'Inativo'}</a> - {user._id !== auth().id && <a href="#" onClick={() => deleteUser(user._id)}>Deletar</a>}
//</li>